import React from 'react'
import Link from 'gatsby-link'
import Logo from '../images/thenewsun-b.png'
import styles from './footer.module.css'
import {Helmet} from 'react-helmet'

const Footer = () => ( 
    <div className={styles.div}>
        <div className={styles.centeredText}>
            All images, text, and other content on this site are licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-NonCommercial 4.0 International License. Click to learn more.</a>
        </div>
    </div>
)

export default Footer
