import React from 'react'
import Link from 'gatsby-link'
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import panel from '../components/panel.module.css'
import styles from './about.module.css'
import {Helmet} from 'react-helmet'

const AboutPage = () => (
    <div>
        <Header />
        <Helmet>
            <title>About</title>
        </Helmet>
        <div className={panel.panel}>
            <div className={panel.centeredText}>
                <h1 className={panel.h}>
                    About
                </h1>
            </div>
            <p>
                Nice to meet you! I'm Rien. I write mostly science fiction and fantasy. 
                I made this website to share with you some of the projects I've been working on. I had fun making them, and
                I hope you have fun going through them, too!  
            </p>
            <p>
                I was born and raised in San Gabriel, California. After suffering through eighteen years of Los Angeles heat, 
                I escaped to Northern California, where I received a degree in computer science from UC Berkeley. Since then, I've been 
                scraping together a meager living as a software engineer and (presently) program manager, waiting for a chance to claim my destiny.
            </p>
            <p>
                I have several interests beyond conquering the world. They include, but are not limited to, talking to people, reading, drawing, 
                game design and development, 
                video/trading card/board games, anime and manga, entrepreneurship, listening to music, philosophy, and education. 
            </p>
            <div className = {panel.centeredText}>
                <h2 className={panel.h}>
                    Q & A
                </h2>
            </div>
            <p>
                <h3>I have a question or comment about a story/character/the website!</h3>
            </p>
            <p>
                Feel free to ask via email or twitter!
            </p>
            <p> 
                <h3>Why is your contact page so sparse? Why not just stick it into the About page?</h3>
            </p>
            <p>
                Honestly, I could do that. But I have nothing else to do with that page at the moment, and I like the site layout enough that I 
                need to maintain four main menu pages... so there it stays.  
            </p>
            <p>
                <h3>If you're publishing your writing here, do you not want to pursue traditional publishing?</h3>
            </p>
            <p>
                None of the texts you can read on this site are stories I intend to publish traditionally; I chose them 
                to help me write on a more consistent schedule with little-to-no editing.
                They absolutely aren't the only ones I'm working on! I am very interested in pursuing traditional publishing as well.
            </p>
            <p>
                <h3>Oxford comma or no?</h3>
            </p>
            <p>
                You know, I didn't care at all about this question until it became important enough to people that they forced me to 
                have an opinion. Then, as some of my friends were quick to make clear, it turned out I had the <i>wrong</i> opinion. 
            </p>
            <p>
                In other words, always Oxford comma.
            </p>
            <p>
                <h3>How do you pronounce .gif? Hard or soft g?</h3>
            </p>
            <p>
                I alternate so as to appease everyone, but I worry that this actually pleases no one.
            </p>
        </div>
        <Footer />
    </div>
)

export default AboutPage
