import React from 'react'
import Link from 'gatsby-link'
import Logo from '../images/thenewsun-b.png'
import styles from './header.module.css'
import {Helmet} from 'react-helmet'

// class Header extends Component { 
// render() {
// return(
//     <div className={styles.div}>
//         <div className={styles.li}><Link to="content">Content</Link></div>
//         <div className={styles.li}><Link to="about">About</Link></div>
//         <div className={styles.logo}><Link to=""><img src={Logo} className={styles.img} /></Link></div>
//         <div className={styles.li}><Link to="contact">Contact</Link></div>
//         <div className={styles.li}><Link to="blog">Blog</Link></div>
//     </div>
// )
// }
// }

const Header = () => ( 
    <div className={styles.div}>
        <Helmet>
            <title>Under New Suns</title>
        </Helmet>
        <div className={styles.li}><div className={styles.text}><Link to="content">Content</Link></div></div>
        <div className={styles.li}><div className={styles.text}><Link to="about">About</Link></div></div>
        <div className={styles.logo}><Link to=""><img src={Logo} className={styles.img} /></Link></div>
        <div className={styles.li}><div className={styles.text}><Link to="contact">Contact</Link></div></div>
        <div className={styles.li}><div className={styles.text}><Link to="blog">Blog</Link></div></div>
    </div>
)

export default Header
